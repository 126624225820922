// 改行を入れたい場合は、「\n」を入れてください。
export default {
  // メール認証とは
  MAIL_AUTH: {
    title: 'メール認証',
    items: [
      {
        subTitle: 'メール認証とは',
        text:
          '電子契約を行う相手方へ認証メールを送信します。\n認証メールでは、相手方が電子契約で利用するメールアドレスの認証と2要素認証のための設定を行います。\n電子契約を行う相手方全員の認証が完了しなければ、契約書類を作成することができません。',
      },
      {
        subTitle: 'メール認証を完了するためには',
        text:
          '電子契約を行う相手方は以下の操作を行う必要があります。\n・メールアドレス認証：ムスベルに登録した電話番号の下4桁の入力\n・2要素認証設定：ID（＝認証済のメールアドレス）、パスワードの設定および所有している携帯電話番号の登録または認証アプリの設定',
      },
    ],
  },

  // 契約者一覧並び替え
  SORT_CUSTOMERS: {
    title: '契約者の並び替えについて',
    items: [
      {
        subTitle: '契約者の並び替えについて',
        text:
          '契約者の並び替えることで新規の契約書類に印字される契約者の順番を変えることができます。※承認依頼済み(作成済み)の契約書類は変更されません。',
      },
    ],
  },

  // 代理人設定とは
  AGENT_SETTING: {
    title: '代理人設定',
    items: [
      {
        subTitle: '代理人の詳細情報',
        text:
          '代理人の詳細情報とは.............................................................................................................................',
      },
    ],
  },
  // 個人ステータスとは
  CUSTOMER_STATUS: {
    title: '個人ステータス',
    items: [
      {
        subTitle: '個人ステータスとは',
        text:
          '契約者ごとの現在の契約状態を表すステータスです。\n' +
          '原契約情報の保存機能を利用し、賃貸借契約書締結後に契約者の追加または削除をする場合に、個人ステータスの更新が必要となります。',
      },
      {
        subTitle: '個人ステータスの更新方法',
        text:
          '■ 賃貸借契約書の締結後に契約者を追加する場合\n' +
          '対象の方の個人ステータスを「追加予定」に更新することで、契約者の増加に関する覚書へ当該契約者の情報の自動印字および書類送信先の選択が可能となります。\n' +
          '契約者の増加に関する覚書の締結後、個人ステータスを「契約中」に変更することで、その他のテンプレートへ当該契約者の情報の自動印字および書類送信先として選択できます。\n' +
          '■ 賃貸借契約書の締結後に契約者を削除する場合\n' +
          '対象の方の個人ステータスを「削除予定」に更新することで、契約者の削除に関する覚書へ当該契約者の情報の自動印字および書類送信先の選択が可能となります。\n' +
          '契約者の削除に関する覚書の締結後、個人ステータスを「削除済」に変更することで、その他テンプレートへ当該契約者の情報は自動印字されず、また、書類送信先で選択ができなくなります。\n' +
          '\n' +
          '※賃貸借契約書が未締結の状態、または原契約情報の保存機能を利用していない場合は、追加する契約者には「契約中」を選択し、削除する契約者には「削除済」を選択してご利用ください'
      },
    ],
  },
  // 監査ログ 承認依頼日とは
  AUDIT_LOG_FILTER_DATE: {
    title: '契約書類セットの作成月',
    items: [
      {
        subTitle: '契約書類セットの作成月',
        text:
          '■ 契約未締結の場合\n' +
          '承認依頼日となります。\n\n' +
          '■ 書面捺印済のPDFをアップロードした場合\n' +
          '登録日となります。'
      },
    ],
  },

  // 連結PDFファイル
  TEMPLATE_CONCAT_PDF: {
    title: '連結ファイル（PDF）',
    items: [
      {
        subTitle: '連結ファイル（PDF）',
        text:
          '登録しているテンプレートファイルの最終ページの後に、任意のPDFファイルを連結させることができます。'
      },
    ],
  },

  // 住宅ローン仮審査申込情報と顧客情報の連携
  LOAN_APPLICATION_CUSTOMER_SELECT: {
    title: '住宅ローン仮審査申込情報と顧客情報の連携について',
    items: [
      {
        subTitle: '住宅ローン仮審査申込情報と顧客情報の連携について',
        text: '顧客情報と連携することで、契約グループ詳細からも住宅ローン仮審査申込情報に遷移することができます。',
      },
    ],
  },

  // 住宅ローン仮審査（SET）申込物件情報管理とは
  ARTICLE_SET_SETTING: {
    title: '住宅ローン仮審査（SET）申込物件情報管理',
    items: [
      {
        subTitle: '住宅ローン仮審査（SET）申込物件情報管理',
        text:
          '住宅ローン仮審査（SET）申込で設定された物件情報をムスベルの物件に設定することで、SETの住宅ローン仮審査情報をムスベルに表示することができます。'
      },
    ],
  },

  // 配布書類の返信種別
  DISTRIBUTION_FILE_REPLY_TYPE: {
    title: '配布書類の返信種別',
    items: [
      {
        subTitle: '返信種別とは',
        text:
          '顧客からのファイル返信が必要かどうかを選択することができます。'
      },
      {
        subTitle: '返信不要',
        text:
          '返信が不要な場合に選択してください。\n顧客は返信操作をすることができません。'
      },
      {
        subTitle: '要返信（代表者返信）',
        text:
          '契約グループ内で、代表者1名からの返信が必要な場合に選択してください。\n返信されたファイルは契約グループ内で共有されます。\n契約グループ内で複数の顧客が返信した場合は最後のファイルで上書きされます。'
      },
      {
        subTitle: '要返信（全員返信）',
        text:
          '全員からの返信が必要な場合に選択してください。\n返信されたファイルは契約グループ内には共有されません。\n複数回返信された場合は最後のファイルで上書きされます。'
      },
    ],
  }
}
